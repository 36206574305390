import type { PropsWithChildren } from 'react';
import { Text } from '@/components/ui';

import { Check } from 'lucide-react';
import React from 'react';
import { cn } from '@/lib/utils';

type Props = PropsWithChildren & {
    isChecked: boolean;
};

const Requirement: React.FC<Props> = ({ children, isChecked }) => {
    return (
        <div className="flex gap-3">
            <div
                className={cn('border-primary size-6 rounded-full border', { ['bg-primary border-white']: isChecked })}
            >
                {isChecked && <Check className="p-0.5 pr-1 text-white" />}
            </div>
            <Text.Body>{children}</Text.Body>
        </div>
    );
};

export default Requirement;
