import React, { useEffect, useState } from 'react';
import { useTranslation } from '@/lib/i18n';
import Step from './Step';
import { stepStore } from '@/lib/cartStore';
import { useStore } from '@nanostores/react';

type StepperProps = {
    initialStep?: number;
};

const Stepper: React.FC<StepperProps> = ({ initialStep = 1 }) => {
    const $stepStore = useStore(stepStore);
    const [currentStep, setCurrentStep] = useState(initialStep);

    const { t } = useTranslation();
    const steps = [
        { step: 1, text: t('order.basket') },
        { step: 2, text: t('order.delivery_and_payment') },
        { step: 3, text: t('order.delivery_information') },
    ];

    useEffect(() => {
        setCurrentStep($stepStore);
    }, [$stepStore]);

    return (
        <div className="flex flex-row justify-center gap-12 md:justify-start">
            {steps.map(({ step, text }) => (
                <Step
                    key={step}
                    isFinished={currentStep > step}
                    isActive={currentStep === step}
                    step={step}
                    text={text}
                />
            ))}
        </div>
    );
};

export default Stepper;
