import React from 'react';
import { useTranslation } from '@/lib/i18n';
import { Button, Text, Input, DialogClose, DialogTitle } from '@/components/ui';
import { useForm } from 'react-hook-form';
import PasswordRequirements from './PasswordRequirements';
import { changePasswordSchema } from '@/components/ui/password-change-form/validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { getFormError } from '@/lib/form';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/lib/store';
import { request } from '@/lib/api';
import Loader from '../loader/Loader';
import { CheckCircle, CircleX } from 'lucide-react';

import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read } from '@expanzi/api-types';
import { cn } from '@/lib/utils';
import Alert from '../alert/Alert';

type FormValues = {
    newPassword: string;
    newPasswordMatch: string;
};

type Props = {
    token?: string | null;
    resetPassword?: boolean;
    user?: User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read | null;
};
const PasswordChangeForm: React.FC<Props> = ({ token, user, resetPassword }) => {
    const { t } = useTranslation();

    const formMethods = useForm<FormValues>({
        resolver: zodResolver(changePasswordSchema),
        mode: 'onBlur',
    });

    const { mutate, isSuccess, isError, isPending } = useMutation<unknown, unknown, FormValues>(
        {
            mutationFn: async (data) => {
                if (resetPassword) {
                    await request(`/api/public/forgot-password/${token}`, {
                        method: 'POST',
                        body: {
                            password: data.newPassword,
                        },
                    });
                } else {
                    await request(`/api/users/${user?.id}`, {
                        method: 'PATCH',
                        token,
                        headers: {
                            'Content-Type': 'application/merge-patch+json',
                        },
                        body: {
                            password: data.newPassword,
                        },
                    });
                }
            },
        },
        queryClient,
    );

    const { isDirty, isValid } = formMethods.formState;
    const getError = getFormError(formMethods.getFieldState, t);

    if (isSuccess) {
        return (
            <>
                <DialogTitle className="flex justify-center">
                    <CheckCircle className="text-success mb-3 size-10" />
                </DialogTitle>

                <div className="flex flex-col items-center justify-center gap-7">
                    <Text.Body bold>{t('password_change.success')}</Text.Body>
                    {resetPassword ? (
                        <Button href="/">{t('login.login')}</Button>
                    ) : (
                        <DialogClose className="w-full" asChild>
                            <Button size="sm" variant="outline" className="mt-auto w-full">
                                {t('close')}
                            </Button>
                        </DialogClose>
                    )}
                </div>
            </>
        );
    }

    if (resetPassword && isError) {
        return (
            <>
                <DialogTitle className="flex justify-center">
                    <CircleX className="text-error mb-3 size-10" />
                </DialogTitle>

                <div className="flex flex-col items-center justify-center gap-7">
                    <Text.Body bold>{t('password_change.reset_error')}</Text.Body>
                    <Button size="sm" variant="outline" className="mt-auto w-full" href="/">
                        {t('close')}
                    </Button>
                </div>
            </>
        );
    }

    return (
        <>
            <DialogTitle>{t('password_change.new_password_title')}</DialogTitle>

            <div>
                <Loader isVisible={isPending} />
                {!resetPassword && isError && <Alert className="mb-6">{t('password_change.error')}</Alert>}
                <form
                    onSubmit={formMethods.handleSubmit((data) => mutate(data))}
                    className={cn('flex flex-col gap-7 opacity-100', { ['opacity-0']: isPending })}
                >
                    <div className="flex flex-col gap-6">
                        <Input
                            id="newPassword"
                            type="password"
                            label={t('password_change.new_password.label')}
                            placeholder={t('password_change.new_password.placeholder')}
                            error={getError('newPassword')}
                            {...formMethods.register('newPassword', { required: true })}
                        />
                        <PasswordRequirements password={formMethods.watch('newPassword')} />
                        <Input
                            id="newPasswordMatch"
                            type="password"
                            label={t('password_change.confirm_new_password.label')}
                            placeholder={t('password_change.confirm_new_password.placeholder')}
                            error={getError('newPasswordMatch')}
                            {...formMethods.register('newPasswordMatch', { required: true })}
                        />
                    </div>
                    <div className="mt-auto flex flex-col gap-3">
                        <Button size="sm" disabled={!isDirty || !isValid}>
                            {t('password_change.change')}
                        </Button>
                        {!resetPassword && (
                            <DialogClose className="w-full" asChild>
                                <Button size="sm" variant="outline" className="w-full">
                                    {t('cancel')}
                                </Button>
                            </DialogClose>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default PasswordChangeForm;
