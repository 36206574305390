import React from 'react';
import { useTranslation } from '@/lib/i18n';
import { Text } from '@/components/ui';

type FactProps = {
    productId?: string;
};

const PackageLeaflet: React.FC<FactProps> = ({ productId }) => {
    const { t } = useTranslation();

    const getNumberOfChemicals = () => {
        switch (productId) {
            case '1':
                return 2;
            case '2':
                return 4;
            case '3':
                return 6;
        }
    };
    return (
        <div className="flex flex-col justify-start gap-10">
            <Text.H2>{t('package_leaflet.title')}</Text.H2>
            <div className="flex flex-col gap-8 md:flex-row">
                <div className="w-full bg-white p-8 md:w-1/2 md:p-12">
                    <Text.H3>{t('package_leaflet.ingredients')}:</Text.H3>
                    <div className="mt-5 flex flex-col gap-4">
                        <Text.Body>{t(`oxino_${productId}.package_leaflet.ingredients`)}</Text.Body>
                        <Text.Body>
                            <Text.Body as="span" bold>
                                {t('package_leaflet.guideline_daily_amount')}:
                            </Text.Body>{' '}
                            {t(`oxino_${productId}.package_leaflet.guideline_daily_amount`)}
                        </Text.Body>
                        <Text.Body>
                            <Text.Body as="span" bold>
                                {t('package_leaflet.warning')}:
                            </Text.Body>{' '}
                            {t(`oxino_${productId}.package_leaflet.warning`)}
                        </Text.Body>
                        <Text.Body bold>
                            {t('package_leaflet.package_contains')}:{' '}
                            {t(`oxino_${productId}.package_leaflet.package_contains`)}
                        </Text.Body>
                    </div>
                </div>
                <div className="w-full md:w-1/2">
                    <div className="divide-primary flex w-full flex-col gap-5 divide-y bg-white p-8 md:p-12">
                        <Text.Body bold>{t(`oxino_${productId}.package_leaflet.active_chemical_title`)}:</Text.Body>
                        <div className="flex flex-col gap-4 pt-4">
                            {[...Array(getNumberOfChemicals()).keys()].map((key) => (
                                <div
                                    key={t(`oxino_${productId}.package_leaflet.active_chemical.${key}.name`)}
                                    className="grid grid-cols-3 gap-4"
                                >
                                    <Text.Body>
                                        {t(`oxino_${productId}.package_leaflet.active_chemical.${key}.name`)}
                                    </Text.Body>
                                    <Text.Body className="text-center">
                                        {t(`oxino_${productId}.package_leaflet.active_chemical.${key}.mg`)}
                                    </Text.Body>
                                    <Text.Body className="text-end">
                                        {t(
                                            `oxino_${productId}.package_leaflet.active_chemical.${key}.reference_intake`,
                                        )}
                                    </Text.Body>
                                </div>
                            ))}
                        </div>
                        <Text.Body className="pt-4">{t('package_leaflet.comments')}</Text.Body>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PackageLeaflet;
