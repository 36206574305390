export const phone = '+420 123 456 789';
export const company = 'Expanzi ltd';
export const email = 'office@expanzi.eu';
export const address = 'Enterprise House 2 Pass Street\nOldham, Manchester\nOL6 6HZ\nUnited Kingdom';
export const registrationNr = '12836768';
export const instagram = 'https://instagram.com';
export const linkedin = 'https://linkedin.com';
export const facebook = 'https://facebook.com';
export const youtube = 'https://youtube.com';
export const euSupplier = {
    company: 'Expanzi ltd - Czech branch',
    registrationNr: '14263645',
    address: {
        street: 'Bořivojova 878/35',
        district: 'Žižkov',
        zip: '130 00',
        city: 'Praha 3',
    },
};
