import { cva } from 'class-variance-authority';
import type { VariantProps } from 'class-variance-authority';

export const headerVariants = cva(['flex', 'flex-col', 'text-white', 'sticky', 'top-0', 'z-10'], {
    variants: {
        color: {
            light: ['bg-accent'],
            dark: ['bg-primary'],
        },
        open: {
            true: ['max-xl:grow', 'max-xl:h-dvh'],
        },
    },
    defaultVariants: {
        color: 'dark',
    },
});

export type HeaderVariantProps = VariantProps<typeof headerVariants>;

export const navVariants = cva(['flex', 'gap-x-8'], {
    variants: {
        vertical: {
            true: ['flex-col', 'items-stretch', 'text-center', 'my-10'],
        },
    },
    defaultVariants: {
        vertical: false,
    },
});

export type NavVariantProps = VariantProps<typeof navVariants>;
