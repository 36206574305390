import React from 'react';
import { PersonVideo } from '@/svg';

import Text from '../text/Text';
import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read } from '@expanzi/api-types';
import { getUserName } from '@/lib/utils.ts';

export type ProfileBadgeProps = {
    user: User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read;
};

const ProfileBadge: React.FC<ProfileBadgeProps> = (props) => {
    const { user } = props;
    return (
        <a href="/profile" className="inline-flex items-center gap-2 rounded-xl bg-white bg-opacity-15 px-4 py-3">
            <PersonVideo />
            <Text.Body className="font-medium" as="span">
                {getUserName(user)}
            </Text.Body>
        </a>
    );
};

export default ProfileBadge;
