import React from 'react';
import { Text } from '@/components/ui';
import cx from 'clsx';
import { Check } from '@/svg';

type StepProps = {
    step: number;
    text: string;
    isActive?: boolean;
    isFinished?: boolean;
};

const Step: React.FC<StepProps> = ({ step, text, isActive = false, isFinished = false }) => {
    return (
        <div className="flex items-center gap-3">
            <div
                className={cx('flex size-8 items-start justify-center rounded-full border-2', {
                    ['bg-accent border-accent text-white']: isActive,
                    ['border-accent text-accent bg-white']: isFinished,
                    ['border-gray-500 bg-white text-gray-500']: !isActive && !isFinished,
                })}
            >
                {!isFinished ? (
                    <Text.Body bold={isActive} className="my-auto">
                        {step}
                    </Text.Body>
                ) : (
                    <Check className="text-accent size-8 pb-0.5" />
                )}
            </div>

            <Text.Body
                className={cx('hidden text-gray-500 md:block', {
                    ['text-accent']: isActive || isFinished,
                })}
            >
                {text}
            </Text.Body>
        </div>
    );
};

export default Step;
