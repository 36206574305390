import React from 'react';
import { cn } from '@/lib/utils';

import Nav from './Nav';
import Basket from './Cart';
import Burger from './Burger';
import Column from './Column';
import UserArea from './UserArea';
import { headerVariants } from './variants';
import { Logo, Currency } from '..';
import type { HeaderVariantProps } from './variants';
import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read } from '@expanzi/api-types';

export type HeaderProps = HeaderVariantProps & {
    referral?: string;
    currency: 'CZK' | 'EUR';
    user: User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read | null;
};

const Header: React.FC<HeaderProps> = (props) => {
    const { color = 'dark', user, referral, currency } = props;

    const [open, setOpen] = React.useState(false);

    return (
        <header className={cn(headerVariants({ color, open }))}>
            <div className="mx-auto flex w-full shrink-0 items-center px-6 py-8 xl:container xl:py-4">
                <Column className="justify-start">
                    <Logo href="/" />
                </Column>
                <Column className="justify-center max-xl:hidden xl:basis-2/4">
                    <Nav />
                </Column>
                <Column className="shrink-0 justify-end gap-x-5">
                    <div className="inline-flex divide-x max-xl:hidden">
                        {/* <Language selected={locale} /> */}
                        <Currency selected={currency} />
                    </div>
                    <div className="size-14">
                        <Basket href="/cart" />
                    </div>

                    <UserArea className="shrink-0 max-xl:hidden" user={user} referral={referral} />
                    <Burger open={open} onClick={() => setOpen((state) => !state)} />
                </Column>
            </div>
            {open && (
                <div className="flex grow flex-col overflow-y-auto px-4 py-12 xl:hidden">
                    <Nav vertical />
                    <div className="mx-auto mb-10 mt-auto inline-flex divide-x py-3">
                        {/* <Language selected={locale} /> */}
                        <Currency selected={currency} />
                    </div>
                    <UserArea className="mx-auto" user={user} referral={referral} />
                </div>
            )}
        </header>
    );
};

export default Header;
