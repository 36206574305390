import React, { useState } from 'react';
import Gallery from './Gallery';
import Controls from './Controls';
import Thumbnails from './Thumbnails';

type CarouselProps = {
    images: string[];
};
const Carousel: React.FC<CarouselProps> = ({ images }) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
    };

    const handleSelectImage = (index: number) => {
        setCurrentIndex(index);
    };

    return (
        <div className="flex h-full w-full gap-6 xl:max-h-[700px]">
            <Thumbnails images={images} onClickImage={handleSelectImage} currentIndex={currentIndex} />
            <div className="bg-surface relative flex w-full grow items-center justify-center">
                <Controls
                    images={images}
                    currentIndex={currentIndex}
                    onClick={handleSelectImage}
                    onClickNext={handleNext}
                    onClickPrevious={handlePrevious}
                />
                <Gallery currentIndex={currentIndex} images={images} onChange={setCurrentIndex} />
            </div>
        </div>
    );
};

export default Carousel;
