import React from 'react';

import { Button, PersonalInformationForm, Dialog, Text, ScrollArea, DialogContent } from '@/components/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type {
    Invoice_jsonld_invoice_write,
    User_jsonld_user_read_file_read_dealer_read_client_read_storeman_read,
} from '@expanzi/api-types';
import { invoiceSchema } from '../cart/validations';
import { useTranslation } from '@/lib/i18n';
import type { CountryCodelist } from '@/lib/resources/codelist';
import Alert from '../alert/Alert';
import { request } from '@/lib/api';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from '@/lib/store';
import ConsentForm from '../consent-form/ConsentForm';
import type { CompanyDetails_jsonld_invoice_write } from '@expanzi/api-types';

import { ENTITY_TYPES } from '@/components/ui/profile/types.ts';

interface Props {
    token?: string | null;
    countries: CountryCodelist[];
    user: User_jsonld_user_read_file_read_dealer_read_client_read_storeman_read | null;
}

export interface PersonalInformationData extends Invoice_jsonld_invoice_write {
    gdprConsent: boolean;
    orderConsent: boolean;
    sameDeliveryAndBilling: string;
    invoiceWanted: boolean;
    billingDetails: CompanyDetails_jsonld_invoice_write;
    deliveryDetails: CompanyDetails_jsonld_invoice_write;
}

const PersonalInformationDialog: React.FC<Props> = (props) => {
    const { countries = [], user, token } = props;

    const { t } = useTranslation();

    const formMethods = useForm<PersonalInformationData>({
        resolver: zodResolver(invoiceSchema),
        defaultValues: {
            sameDeliveryAndBilling: 'none',
            gdprConsent: true,
            orderConsent: true,
            invoiceWanted: false,
            billingDetails: {
                contactPersonEmail: user?.email,
                contactPersonLastName: user?.profile?.lastName,
                contactPersonFirstName: user?.profile?.firstName,
            },
            deliveryDetails: {
                contactPersonEmail: user?.email,
                contactPersonLastName: user?.profile?.lastName,
                contactPersonFirstName: user?.profile?.firstName,
            },
        },
        mode: 'onBlur',
    });

    const prepareData = (data: PersonalInformationData): Invoice_jsonld_invoice_write => {
        data.billingDetails.entityType = data.invoiceWanted ? ENTITY_TYPES.COMPANY : ENTITY_TYPES.PERSON;
        if (data.sameDeliveryAndBilling) {
            data.deliveryDetails = {
                ...data.billingDetails,
            };
        }
        if (data.invoiceWanted) {
            data.deliveryDetails = {
                ...data.deliveryDetails,
                name: data.billingDetails.name,
                ico: data.billingDetails.ico,
                dic: data.billingDetails.dic,
                entityType: data.billingDetails.entityType,
            };
        }

        return data;
    };

    const { mutate, isError, isPending, isSuccess } = useMutation<unknown, unknown, PersonalInformationData>(
        {
            mutationFn: async (rawData) => {
                const data = prepareData(rawData);

                await request(`/api/users/${user?.id}`, {
                    method: 'PATCH',
                    token,
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                    body: {
                        profile: {
                            lastName: data?.billingDetails?.contactPersonLastName,
                            firstName: data?.billingDetails?.contactPersonFirstName,
                            phoneNumber: data?.billingDetails?.contactPersonPhoneNumber,
                        },
                    },
                });
                await request(`/api/users/${user?.id}/client`, {
                    method: 'POST',
                    token,
                    body: data,
                });
            },
            onSuccess: () => {
                window.location.href = new URL(window.location.href).toString();
            },
        },
        queryClient,
    );

    return (
        <Dialog defaultOpen>
            <DialogContent className="h-full max-h-[calc(100vh-4rem)] min-w-[80%] !py-0">
                <ScrollArea>
                    <FormProvider {...formMethods}>
                        <form
                            className="flex min-h-[calc(100vh-4rem)] flex-col gap-6 py-6"
                            onSubmit={formMethods.handleSubmit((data) => mutate(data))}
                        >
                            {isError && <Alert>{t('register.finalize_error')}</Alert>}
                            <PersonalInformationForm
                                user={user}
                                allCountries={countries}
                                deliveryCountries={countries.filter((country) => country.deliveryAllowed)}
                            />
                            <ConsentForm defaultChecked className="hidden" />
                            <div className="flex flex-1 items-center justify-between gap-4">
                                <Text.Body>{t('fields_required')}</Text.Body>
                                <Button disabled={isPending || isSuccess} type="submit">
                                    {t('submit')}
                                </Button>
                            </div>
                        </form>
                    </FormProvider>
                </ScrollArea>
            </DialogContent>
        </Dialog>
    );
};

export default PersonalInformationDialog;
