import React, { useCallback, useState } from 'react';
import { Basket } from '@/svg';
import { cn } from '@/lib/utils';
import { formatProductCount } from '@/lib/cart';
import { Text } from '@/components/ui';
import useCartStorageEvent from '@/hooks/cartHook';
import { useIsClient } from '@/hooks/ssr';

export type ButtonProps = React.AnchorHTMLAttributes<HTMLAnchorElement>;

const Cart: React.FC<ButtonProps> = ({ className, ...rest }) => {
    const [productCount, setProductCount] = useState<string | null>(formatProductCount());

    const handleStorageEvent = useCallback(() => {
        setProductCount(formatProductCount());
    }, []);

    useCartStorageEvent(handleStorageEvent);

    const isClient = useIsClient();

    return (
        <a
            {...rest}
            className={cn(
                'relative inline-flex size-14 items-center justify-center rounded-full border border-white bg-white bg-opacity-15 p-2',
                className,
            )}
        >
            {isClient && productCount && (
                <div className="bg-error absolute left-0 top-0 flex min-h-6 min-w-6 items-center justify-center rounded-full p-1">
                    <Text.Body bold className="text-xs text-white ">
                        {productCount}
                    </Text.Body>
                </div>
            )}
            <Basket />
        </a>
    );
};

export default Cart;
