import React from 'react';
import { cn } from '@/lib/utils';
import { useTranslation } from '@/lib/i18n';

import { Text } from '..';

const Hero = () => {
    const { t } = useTranslation();
    return (
        <div className="bg-accent text-white">
            <div
                className={cn([
                    'md:container',
                    'mx-auto',
                    'h-[47rem]',
                    'sm:h-[49.75rem]',
                    "bg-[url('/img/hero-mobile.png')]",
                    "md:bg-[url('/img/hero-desktop.png')]",
                    'bg-contain',
                    'bg-no-repeat',
                    'xl:bg-[length:80%_auto]',
                    'bg-[center_bottom_1rem]',
                    'sm:max-md:bg-[center_bottom_-1rem]',
                    'lg:bg-[center_bottom_1rem]',
                    'xl:bg-[center_bottom_2.5rem]',
                ])}
            >
                <div className="mx-6 space-y-8 py-8 md:py-24">
                    <Text.Hero className="uppercase">{t('hero.title')}</Text.Hero>
                    <Text.Subtitle className="max-w-md">{t('hero.subtitle')}</Text.Subtitle>
                </div>
            </div>
        </div>
    );
};

export default Hero;
