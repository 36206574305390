import React from 'react';
import { signOut } from 'auth-astro/client';
import { useTranslation } from '@/lib/i18n';
import type { Invoice } from '@/lib/resources/invoices';
import type { CountryCodelist } from '@/lib/resources/codelist';
import type { User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read } from '@expanzi/api-types';

import Text from '../text/Text';
import Button from '../button/Button';
import Pagination from '../pagination/Pagination';
import OrderLink from '../order-link/OrderLink';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../tabs/Tabs';
import BillingDetailsForm from './BillingDetailsForm';
import DeliveryDetailsForm from './DeliveryDetailsForm';
import { NoOrdersCard } from '@/components/ui';
import ProfileSettings from './ProfileSettings';
import type { DeliveryMethod } from '@/lib/resources/delivery.ts';
import { getUserName } from '@/lib/utils.ts';

export type ProfileProps = {
    invoices: {
        data: Invoice[];
        url: string;
        page: number;
        itemsPerPage: number;
        totalItems: number;
    };
    token?: string | null;
    countries: CountryCodelist[];
    user: User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read;
    deliveryMethods: DeliveryMethod[];
};

const Profile: React.FC<ProfileProps> = (props) => {
    const { user, invoices, countries, token, deliveryMethods } = props;
    const { t } = useTranslation();

    const filteredInvoices = invoices.data.filter((invoice) => !invoice.licenseOrder);

    return (
        <div className="order-2 flex flex-1 flex-col gap-12 lg:order-1 ">
            <Text.H2 className="font-bold">{t('profile.hello', { name: getUserName(user) })}</Text.H2>
            <Tabs defaultValue="orders">
                <div className="flex items-center justify-center lg:justify-between">
                    <TabsList>
                        <TabsTrigger value="orders">{t('profile.orders')}</TabsTrigger>
                        <TabsTrigger value="billing_details">{t('profile.billing_details')}</TabsTrigger>
                        <TabsTrigger value="delivery_details">{t('profile.delivery_details')}</TabsTrigger>
                        <TabsTrigger value="settings">{t('profile.settings')}</TabsTrigger>
                    </TabsList>
                    <Button
                        className="hidden lg:block"
                        variant="outline"
                        size="sm"
                        type="button"
                        onClick={() =>
                            signOut({
                                callbackUrl: '/',
                            })
                        }
                    >
                        {t('profile.logout')}
                    </Button>
                </div>
                <TabsContent value="orders">
                    <div className="mt-6 flex w-full flex-col gap-6">
                        {filteredInvoices.length > 0 ? (
                            filteredInvoices.map((invoice) => (
                                <OrderLink key={invoice.id} invoice={invoice} deliveryMethods={deliveryMethods} />
                            ))
                        ) : (
                            <NoOrdersCard />
                        )}
                        <Pagination
                            url={invoices.url}
                            currentPage={invoices.page}
                            nrOfItems={invoices.totalItems}
                            itemsPerPage={invoices.itemsPerPage}
                        />
                    </div>
                </TabsContent>
                <TabsContent value="delivery_details">
                    <DeliveryDetailsForm
                        user={user}
                        token={token}
                        countries={countries.filter((country) => country.deliveryAllowed)}
                    />
                </TabsContent>
                <TabsContent value="billing_details">
                    <BillingDetailsForm countries={countries} user={user} token={token} />
                </TabsContent>
                <TabsContent value="settings">
                    <ProfileSettings token={token} user={user} />
                </TabsContent>
            </Tabs>
            <Button className="block lg:hidden" variant="outline" size="sm" type="button" onClick={() => signOut()}>
                {t('profile.logout')}
            </Button>
        </div>
    );
};

export default Profile;
