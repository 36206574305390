import React from 'react';

import { Button, Dialog, DialogContent, DialogDescription, DialogTitle } from '..';
import { useTranslation } from '@/lib/i18n';
import { request } from '@/lib/api';
import { cn } from '@/lib/utils';
import Alert from '../alert/Alert';
import { Checkmark } from '@/svg';
import Loader from '../loader/Loader';
import { TOKEN_PARAM } from '@/constants/params';
import { signIn } from 'auth-astro/client';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '@/lib/store';

interface Props {
    urlParams?: string;
}

const VerifyDialog: React.FC<Props> = (props) => {
    const { urlParams = '' } = props;

    const { t } = useTranslation();

    const params = new URLSearchParams(urlParams);

    const isVerify = Boolean(params?.get(TOKEN_PARAM));

    const { mutate, isPending, isSuccess } = useMutation<unknown, unknown, Record<string, unknown>>(
        {
            mutationFn: (formData) =>
                signIn('credentials', {
                    redirect: false,
                    user: JSON.stringify(formData),
                })
                    .then((res) => res?.json())
                    .then((data) => {
                        let hasError = false;
                        try {
                            const url = new URL(data?.url ?? '');
                            hasError = Boolean(url.searchParams.get('error'));
                        } catch (_) {}
                        if (hasError) {
                            throw new Error('Error');
                        }
                        return hasError;
                    }),
        },
        queryClient,
    );

    const { isError, data, isLoading } = useQuery<Record<string, unknown>>(
        {
            enabled: isVerify,
            queryKey: ['verify', params.get('id')],
            queryFn: () =>
                request('/api/public/welcome-email/verify', {
                    method: 'GET',
                    query: {
                        id: params.get('id') ?? '',
                        token: params.get(TOKEN_PARAM) ?? '',
                        expires: params.get('expires') ?? '',
                        signature: params.get('signature') ?? '',
                    },
                }).then(({ data }) => data),
        },
        queryClient,
    );

    const pendingClassName = cn('opacity-100', { ['opacity-0']: isLoading || isError });

    return (
        <Dialog
            defaultOpen={isVerify}
            onOpenChange={() => {
                window.location.href = '/';
            }}
        >
            <DialogContent>
                <div className="relative">
                    <Loader isVisible={isLoading} />
                    {isError && <Alert className="mb-6">{t('register.verified_error')}</Alert>}
                    <Checkmark className={cn('text-success mx-auto mb-6', pendingClassName)} />
                    <DialogTitle className={pendingClassName}>{t('register.verified_title')}</DialogTitle>
                    <DialogDescription className={pendingClassName}>
                        {t('register.verified_description')}
                    </DialogDescription>
                    <Button
                        onClick={() => {
                            if (data) {
                                mutate(data);
                            } else {
                                const url = new URL(window.location.href);
                                url.searchParams.delete('id');
                                url.searchParams.delete(TOKEN_PARAM);
                                url.searchParams.delete('expires');
                                url.searchParams.delete('signature');
                                window.location.href = url.toString();
                            }
                        }}
                        className={cn('w-full', pendingClassName)}
                        disabled={isPending || isSuccess}
                        type="button"
                    >
                        {t('register.continue')}
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default VerifyDialog;
